/* ##remfixer: 1080 */
.HotTagList {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.HotTagList .BlockBox li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.HotTagList .BlockBox li.last {
  border: none;
}
.content {
  width: 100%;
  overflow: hidden;
}

/*# sourceMappingURL=HotTagList-vue.f8518643.css.map*/